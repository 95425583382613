import React, { useState } from "react";
import Button from "../common/buttons/Button";
import PortableText from "react-portable-text";

const Detail = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleChangeQuestion(index) {
    setSelectedIndex(index);
  }

  return (
    <div className="relative block w-full box-border mt-[40px] show">
      <div className="relative max-w-[1440px] px-5 md:px-0 w-full mx-auto block">
        <div className="p-[10px] sm:p-5 block w-full">
          <p className="text-[40px] sm:text-[50px] leading-14 sm:leading-[63px] font-lora text-secondary font-semibold text-center mb-[10px]">
            {data?.heading}
          </p>
          {/* Buttons Section */}
          <div className="w-full md:w-3/4 mx-auto block text-center">
            {data?.detailList?.map((item, index) => (
              <button
                key={index}
                className={`inline-block p-[6px] m-[6px] uppercase rounded-[5px] text-center no-underline 
                whitespace-nowrap border border-solid border-[#ccc]
                box-border transition-all duration-200 ease-linear tracking-[1px] ${
                  selectedIndex === index
                    ? "bg-primary text-white"
                    : "bg-transparent hover:text-primary"
                } `}
                onClick={() => handleChangeQuestion(index)}
                aria-label="button-detail"
              >
                {item.tab}
              </button>
            ))}
          </div>
          {/* Content Section */}
          <div className="mt-[50px]">
            {data?.detailList?.map((item, index) => {
              if (selectedIndex === index) {
                return (
                  <div className="max-w-[850px] mx-auto g-scaleIn" key={index}>
                    {item?.detail?.map((inner, i) => {
                      return (
                        <div className="mb-7" key={i}>
                          {inner?.svg && (
                            <div
                              dangerouslySetInnerHTML={{ __html: inner.svg }}
                              className="flex justify-center mb-5"
                            />
                          )}
                          <p
                            className={`text-[26px] sm:text-[30px] mb-10 leading-[39px] ${
                              inner.textColor === "dark"
                                ? "text-assent"
                                : "text-secondary"
                            } text-center font-semibold`}
                          >
                            {inner.heading}
                          </p>
                          {(inner?._rawDetail || inner?.detail) && (
                            <PortableText
                              content={inner?._rawDetail || inner?.detail}
                              className={`portable-editor ${
                                inner.textColor === "dark"
                                  ? "text-assent"
                                  : "text-secondary"
                              } ${
                                inner?.alignment
                                  ? inner?.alignment
                                  : "text-left"
                              } block-content max-w-none leading-[26px] text-sm sm:text-base font-semibold`}
                              serializers={{
                                ul: ({ children }) => (
                                  <ul className="p-0 m-0 ml-3 -mt-5 list-none">
                                    {children}
                                  </ul>
                                ),
                                li: ({ children }) => (
                                  <li className="p-0 ml-7 my-1 before:content-[''] before:h-[8px] before:w-[8px] before:rounded-full before:border before:border-[#ccc] before:inline-block before:mr-4 ">
                                    {children}
                                  </li>
                                ),
                                h3: ({ children }) => (
                                  <h3 className="text-[40px] leading-[54px] text-assent text-center font-semibold">
                                    {children}
                                  </h3>
                                ),
                                h4: ({ children }) => (
                                  <h3 className="text-[20px] leading-[27px] max-w-[777px] mx-auto w-full py-3 tracking-[2px] bg-[#ccc] text-assent uppercase text-center font-semibold">
                                    {children}
                                  </h3>
                                ),
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}

            {/* {selectedIndex === 1 && (
              <div className="flex justify-center flex-col items-center g-scaleIn">
                <p className="text-[30px] leading-[39px] text-assent text-center font-semibold">
                  2020 - 2021 RATES
                </p>
                <p className="text-[20px] leading-[27px] max-w-[777px] w-full py-3 mt-10 tracking-[2px] bg-[#ccc] text-assent uppercase text-center font-semibold">
                  DAY TOUR WITH TRANSPORTATION
                </p>
                <p className="text-[40px] leading-[54px] text-assent text-center font-semibold">
                  $80
                </p>
                <p className="text-base leading-[26px] text-assent text-center mt-10 font-semibold">
                  <span className="block">PER PERSON</span>
                  <br />
                  <span className="block">CHILDREN 15 & UNDER</span>
                  <span className="block">$40</span>
                  <br />
                  <span className="block">MINIMUM AGE: 5 YEARS OLD</span>
                  <br />
                  <span className="block">MINIMUM GROUP: TWO ADULTS</span>
                  <br />
                  <span className="block">MAXIMUM GROUP: SIX PEOPLE</span>
                  <br />
                  <span className="block">
                    TRANSPORTATION FROM ISLA COLON INCLUDED WITH PRICE.
                  </span>
                  <br />
                  <span className="block">7% ITBMS IN ADDITION TO PRICE.</span>
                  <br />
                  <span className="block">
                    AVAILABLE MONDAY THROUGH SATURDAY
                  </span>
                </p>
              </div>
            )} */}
            {/* {selectedIndex === 2 && (
              <div className=" max-w-[850px] mx-auto g-scaleIn">
                <p className="text-[30px] leading-[39px] text-assent text-center font-semibold">
                  WHAT'S INCLUDED
                </p>

                <p className="text-base leading-[26px] text-assent  mt-10 font-semibold">
                  <span className="block">
                    Included for the day: guided activities at or near Tranquilo
                    Bay Eco Adventure Lodge, lunch, use of equipment and
                    facilities as part of the tour.
                  </span>
                  <br />
                  <span className="block">
                    Beverages included are water, fruit juices, and/or soda. No
                    alcoholic beverages are included.
                  </span>

                  <br />
                  <span className="block">
                    Transportation to and from lodge may be included by
                    Tranquilo Bay as designated in your reservation email.
                    Payment is due in order to receive information regarding
                    transportation departure location, etc.
                  </span>
                  <br />
                  <span className="block">
                    Boat captains and boats may not stay at Tranquilo Bay during
                    day tour. They will be onsite to pick up guests at 3:30 pm.
                  </span>
                </p>
              </div>
            )} */}
            {/* {selectedIndex === 3 && (
              <div className=" max-w-[850px] mx-auto g-scaleIn">
                <p className="text-[30px] leading-[39px] text-assent text-center font-semibold">
                  WHAT TO BRING
                </p>

                <p className="text-base leading-[26px] text-assent  mt-10 font-semibold">
                  <span className="block">Rain Jacket</span>
                  <br />
                  <span className="block">Reef Safe Sunscreen</span>

                  <br />
                  <span className="block">
                    Closed Toe Shoes That Can Get Wet for Hiking
                  </span>
                  <br />
                  <span className="block">
                    Long Pants and Lightweight Long-Sleeve Shirt for Hiking
                  </span>
                  <br />
                  <span className="block">
                    Rash Guard or Swim Shirt for Snorkeling
                  </span>
                  <br />
                  <span className="block">
                    Dry Bag for Items You Might Want to Take with You in the
                    Kayak
                  </span>
                  <br />
                  <span className="block">Towel</span>
                  <br />
                  <span className="block">Hat</span>
                  <br />
                  <span className="block">Sunglasses</span>
                </p>
              </div>
            )} */}
            {/* {selectedIndex === 4 && (
              <div className=" max-w-[920px] mx-auto g-scaleIn">
                <p className="text-[30px] leading-[39px] text-assent text-center font-semibold">
                  BIOSECURITY PROTOCOL
                </p>

                <p className="text-base leading-[26px] text-assent  mt-10 font-semibold">
                  <span className="block">
                    Our team has been hard at work to ensure that necessary
                    measures are in place to ensure both guests and our team
                    members remain safe and healthy.
                  </span>
                  <br />
                  <span className="block">
                    Tranquilo Bay Eco Adventure Lodge is taking precautions to
                    minimize exposure by enacting protocols based on
                    recommendations, guidelines, and requirements issued by
                    numerous North American health organizations, international
                    organizations, and the Panamanian public health agency.
                  </span>
                </p>
                <div className="mt-10">
                  <div>
                    <div className="flex flex-col justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        height="60"
                        width="60"
                      >
                        <g fill="#111111">
                          <path d="M.15 184.42C-2.17 244.21 23 298.06 64 334.88V512h160V316.51L3.67 156.25a182.28 182.28 0 0 0-3.52 28.17zM509.22 275c-21-47.12-48.5-151.75-73.12-186.75A208.11 208.11 0 0 0 266.11 0H200C117 0 42.48 50.57 13.25 123.65L239.21 288h272.55a31.35 31.35 0 0 0-2.54-13zM320 224a32 32 0 1 1 32-32 32.07 32.07 0 0 1-32 32zm16 144h160l16-48H256v192h145.88a64 64 0 0 0 60.71-43.76L464 464H336a16 16 0 0 1 0-32h138.67l10.67-32H336a16 16 0 0 1 0-32z"></path>
                        </g>
                      </svg>
                      <p className="text-[30px] mt-5 tracking-[2px] leading-[39px] text-assent text-center font-semibold uppercase">
                        MASKS
                      </p>
                    </div>
                    <p className="text-base leading-[26px] text-assent  mt-9 font-semibold">
                      Guests are encouraged to wear a mask at all times when
                      they are with the group other than when kayaking,
                      snorkeling or eating.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div>
                    <div className="flex flex-col justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        height="53"
                        width="60"
                      >
                        <g fill="#666">
                          <path d="M496 224a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-184.53-45.55A56.77 56.77 0 0 1 328 176a56 56 0 0 1 19 3.49l15.35-48.61A24 24 0 0 0 342 99.74c-11.53-1.35-22.21 6.44-25.71 17.51l-20.9 66.17zM93.65 386.33c.8-.19 1.54-.54 2.35-.71v-25.69a156 156 0 0 1 107.06-148l73.7-22.76 34.16-108.12a24 24 0 0 0-20.33-31.11c-11.53-1.34-22.22 6.45-25.72 17.52l-33.45 106.42a8 8 0 0 1-15.26-4.83l43.37-137.79A24 24 0 0 0 239.2.15C227.67-1.19 217 6.6 213.49 17.66l-47.93 151.71a8 8 0 1 1-15.26-4.82l38.56-122a24 24 0 0 0-20.33-31.11C157 10 146.32 17.83 142.82 28.9l-60 189.85-2.06-50.05a24 24 0 0 0-23.86-24.15c-13.23-.05-24.72 10.54-24.9 23.86v112.73a123.69 123.69 0 0 0 61.65 105.19zM519.1 336H360a8 8 0 0 1 0-16h128a24 24 0 0 0 23.54-28.76c-2.19-11.4-12.83-19.24-24.44-19.24H288l47.09-17.06a24 24 0 0 0-14.18-45.88l-107.72 33.25A123.88 123.88 0 0 0 128 360v25.65a79.78 79.78 0 0 1 58 108.63A118.9 118.9 0 0 0 248 512h208a24 24 0 0 0 23.54-28.76c-2.19-11.4-12.83-19.24-24.44-19.24H360a8 8 0 0 1 0-16h128a24 24 0 0 0 23.54-28.76c-2.19-11.4-12.83-19.24-24.44-19.24H360a8 8 0 0 1 0-16h160a24 24 0 0 0 23.54-28.76c-2.19-11.4-12.83-19.24-24.44-19.24zM416 64a32 32 0 1 0-32-32 32 32 0 0 0 32 32zM112 416a48 48 0 1 0 48 48 48 48 0 0 0-48-48z"></path>
                        </g>
                      </svg>
                      <p className="text-[30px] mt-5 tracking-[2px] leading-[39px] text-secondary text-center font-semibold uppercase">
                        GENERAL PRECAUTIONS AND REMINDERS
                      </p>
                    </div>
                    <p className="text-base leading-[26px] text-assent  mt-9 font-semibold">
                      <span className="block">
                        All public spaces, dining areas, bathrooms, etc. will be
                        sanitized 3x/day
                      </span>
                      <br />
                      <span className="block">
                        Hand sanitizer will be readily available throughout the
                        property
                      </span>
                      <br />
                      <span className="block">
                        Be sure to cough or sneeze into your elbow and away from
                        others
                      </span>
                      <br />
                      <span className="block">
                        Frequently and thoroughly wash your hands either with
                        soap and hot running water for at least 20 seconds or
                        with alcohol-based hand sanitizers
                      </span>
                      <br />
                      <span className="block">
                        Daily temperature checks will be taken in a sanitary
                        manner for all employees and guests. Employees with an
                        elevated temperature will not work.
                      </span>
                    </p>
                  </div>
                  <br />
                  <br />
                  <div>
                    <div className="flex flex-col justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        height="60"
                        width="60"
                      >
                        <g fill="#111111">
                          <path d="M40 42c-2.78 0-5.56-.94-8-2.65-4.88 3.42-11.12 3.42-16 0C13.56 41.06 10.78 42 8 42H4v4h4c2.75 0 5.48-.69 8-1.99 5.04 2.59 10.96 2.59 16 0C34.52 45.3 37.25 46 40 46h4v-4h-4zM7.89 38H8c3.2 0 6.05-1.76 8-4 1.95 2.24 4.8 4 8 4s6.05-1.76 8-4c1.96 2.24 4.79 4 8 4h.11l3.79-13.37c.17-.51.12-1.07-.12-1.55-.25-.48-.68-.84-1.2-.99L40 21.24V12c0-2.21-1.79-4-4-4h-6V2H18v6h-6c-2.21 0-4 1.79-4 4v9.24l-2.57.84c-.52.16-.95.51-1.2.99s-.29 1.04-.12 1.55L7.89 38zM12 12h24v7.93L24 16l-12 3.93V12z"></path>
                        </g>
                      </svg>
                      <p className="text-[30px] mt-5 tracking-[2px] leading-[39px] text-assent text-center font-semibold uppercase">
                        ARRIVAL
                      </p>
                    </div>
                    <p className="text-base leading-[26px] text-assent  mt-9 font-semibold">
                      Guest temperature will be checked (must be below 100.4 F
                      or 38) to enter Tranquilo Bay’s boat
                    </p>
                  </div>
                  <br />
                  <br />
                  <div>
                    <div className="flex flex-col justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        height="60"
                        width="60"
                      >
                        <g fill="#666">
                          <path d="M16.2 26.69l5.66-5.66L7.83 7c-3.12 3.12-3.12 8.19 0 11.31l8.37 8.38zm13.57-3.63c3.05 1.43 7.36.42 10.54-2.76 3.83-3.83 4.56-9.3 1.63-12.23C39 5.14 33.52 5.87 29.7 9.7c-3.18 3.18-4.18 7.49-2.76 10.54-4.45 4.44-19.53 19.52-19.53 19.52l2.83 2.83L24 28.83l13.76 13.76 2.83-2.83L26.83 26l2.94-2.94z"></path>
                        </g>
                      </svg>
                      <p className="text-[30px] mt-5 tracking-[2px] leading-[39px] text-secondary text-center font-semibold uppercase">
                        DINING
                      </p>
                    </div>
                    <p className="text-base leading-[26px] text-assent  mt-9 font-semibold">
                      Meals will be served picnic style at the dock. Guests
                      should distance themselves apart accordingly.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      height="60"
                      width="60"
                    >
                      <g fill="#111111">
                        <path d="M40 24c0-2.21 1.79-4 4-4v-8c0-2.21-1.79-4-4-4H8c-2.21 0-3.98 1.79-3.98 4l-.01 8C6.22 20.01 8 21.8 8 24c0 2.21-1.79 4-3.99 4L4 36c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4v-8c-2.21 0-4-1.79-4-4zm-8.84 9.6L24 29l-7.16 4.6L19 25.37l-6.58-5.38 8.49-.5L24 11.6l3.09 7.89 8.49.5L29 25.37l2.16 8.23z"></path>
                      </g>
                    </svg>
                    <p className="text-[30px] mt-5 tracking-[2px] leading-[39px] text-assent text-center font-semibold uppercase">
                      PROGRAMMING ACTIVITIES & EXCURSIONS
                    </p>
                  </div>
                  <p className="text-base leading-[26px] text-assent  mt-9 font-semibold">
                    Guests, except those traveling together, will attempt to
                    remain at least 6’ apart during activities and excursions
                  </p>
                </div>
              </div>
            )} */}
            <div className="w-[300px] sm:w-[349px] mx-auto mt-[70px]">
              {data?.ctaButton?.title && (
                <Button
                  btnText={data?.ctaButton?.title}
                  btnLink={data?.ctaButton?.link}
                  btnType={data?.ctaButton?.variant}
                  linkType={data?.ctaButton?.type}
                  formId={data?.ctaButton?.formId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
