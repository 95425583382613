import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import FeaturedList from "../components/common/featuredlist";
// import ContentSlider from "../components/daytour/ContentSlider";
import SliderAndContent from "../components/common/SliderAndContent";
import Handcrafted from "../components/daytour/Handcrafted";
import Detail from "../components/daytour/Detail";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import Seo from "../components/seo";

const Daytour = ({ data: { sanityDaytourPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let features;
  let guide;
  let hand;
  let daytourList;

  sanityDaytourPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "handSection") {
      hand = element;
    } else if (element._type === "daytourList") {
      daytourList = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h2Size="text-3xl sm:text-[50px] leading-[45px] sm:leading-[60px]"
        h2P="pb-7"
      />
      {sanitySitesettings?.logo && <Logo logo={sanitySitesettings.logo} />}
      {features && (
        <div className="-m-5">
          <FeaturedList data={features} />
        </div>
      )}
      {sanityDaytourPage?.contentslider?.map((item, index) => (
        <SliderAndContent
          data={item}
          key={index}
          sliderId={`daytour-1-${index}`}
        />
      ))}

      {hand && (
        <Handcrafted
          data={hand}
          bgUrl={`linear-gradient(rgba(192, 192, 192, 0.90),rgba(121, 121, 121, 0.90)),url(${hand?.sectionBg?.asset.url})`}
          bgSize="initial"
        />
      )}
      {sanityDaytourPage?.contentslider2?.map((item, index) => (
        <SliderAndContent
          data={item}
          key={index}
          sliderId={`daytour-2-${index}`}
        />
      ))}

      {daytourList && <Detail data={daytourList} />}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {guide && (
        <div>
          <CompaniesGuided data={guide} />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
};

export default Daytour;

export const query = graphql`
  query {
    sanityDaytourPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityHandSection {
          _type
          comment
          heading
          sectionBg {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          _rawCotent
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityDaytourList {
          _type
          heading
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          detailList {
            tab
            detail {
              heading
              alignment
              svg
              textColor
              _rawDetail
            }
          }
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }

        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
      }

      contentslider {
        title
        bgRepeat
        bgSize
        direction
        height
        overlay
        size
        textColor
        paragraphTitle
        sectionBG {
          asset {
            url
          }
        }
        ctaButton1 {
          link
          title
          type
          variant
          formId
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
        }
        ctaButton3 {
          link
          title
          type
          variant
          formId
        }
        sliderImages {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
          }
        }
        _rawContent
        _rawContent2
      }
      contentslider2 {
        title
        bgRepeat
        bgSize
        direction
        height
        overlay
        size
        textColor
        paragraphTitle
        sectionBG {
          asset {
            url
          }
        }
        ctaButton1 {
          link
          title
          type
          variant
          formId
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
        }
        ctaButton3 {
          link
          title
          type
          variant
          formId
        }
        sliderImages {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
          }
        }
        _rawContent
        _rawContent2
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
